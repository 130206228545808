import adddelegateteamdialog from '../../joinmatch/components/adddelegateteamdialog';
import {

	Message
} from 'element-ui'
import {
	handleQueryMatchInfoByInvitateCode,
	handleJoinTeamSave,
	handlePageQueryMyJoinMatchs,
	handleMyTeams

} from '@index/api/team/matchinfo';
export default {
	name: 'myjoinmatch',
	components: {
		adddelegateteamdialog
	},
	data() {
		return {
			myteams: [],
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			matchDialogVisible: false,
			matchForm: { teamId: '', contacts: '', contactsPhone: '' },
			tableData: [],
			currTeam: {},
			queryForm: {},
			matchId: '',
			states: [{ value: '0', label: '报名中' }, { value: '1', label: '报名截止' }, { value: '2', label: '比赛中' }, { value: '3', label: '比赛结束' }],
			matchDialogStep1Visible: false,
			matchDialogStep2Visible: false,
			matchDialogStep3Visible: false,
		};
	},
	watch: {


	},
	created() {
		this.pageQueryMyJoinMatchs();
	},
	mounted() {
	
	},
	destroyed() {
		// window.removeEventListener('storage', this.afterQRScan)
	},
	methods: {
		fastQuery() { //快速查询选手
			this.currPage1 = 1;
			this.pageQueryMyJoinMatchs();
		},
		toMatch(id, joinState) { //进入比赛
			if ('1' == joinState) { //禁止参数
				Message({
					type: 'error',
					message: '已禁止参赛，请联系管理员!',
					duration: 5000
				})
			} else {
				sessionStorage.setItem('joinMatchId', id);
				this.$router.push({
					path: '/joinmatch',
					query: {
						menusShow: true,
						id: id
					}
				})
			}


		},
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQueryMyJoinMatchs();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQueryMyJoinMatchs();
		},
		queryMatchInfoByInvitateCode(callback) { //根据参赛码获取比赛信息
			handleQueryMatchInfoByInvitateCode(this.matchForm.invitateCode).then(res => {
				if (res.status == 200) {
					if (res.data) {
						if (!res.data.matchInfo || res.data.matchInfo == null) {
							this.$message.error('请输入正确的邀请码');
							return;
						}
						this.matchId = res.data.matchInfo.id;
						this.matchForm = {
							...this.matchForm,
							...res.data.matchInfo
						};
						if (callback) {
							callback();
						}
						return;
					}
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					})
				}

			})
		},
		joinTeamSave() { //参加比赛	
			this.matchForm.id = this.matchId;
			handleJoinTeamSave(this.matchForm).then(res => {
				if (res.status == 200) {
					Message({
						type: 'success',
						message: res.msg,
						duration: 5000
					})
					this.matchDialogVisible = false;
					this.myteams.forEach(item => {
						if (item.id == this.matchForm.teamId) {
							this.matchForm.teamName = item.name;
							return false;
						}
					})
					this.matchDialogStep2Visible = false;
					this.matchDialogStep3Visible = true;
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					})
				}

			});
		},
		pageQueryMyJoinMatchs() { //分页查询我参加的比赛
			handlePageQueryMyJoinMatchs({
				pageSize: this.pageSize,
				currPage: this.currPage,
				matchName: this.queryForm.matchName,
				state: this.queryForm.state,
				sortWay: [{
					fieldName: 's.createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					var temp = [];
					// for (var i = 0; i < 100; i++) {
					// 	temp = temp.concat(res.data);
					// }
					temp = res.data;
					this.tableData = temp;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					})
				}
			});
		},
		queryMyTeams() { //我的代表队
			handleMyTeams().then(res => {
				if (res.status == 200) {
					this.myteams = res.data;
				} else {
					Message({
						type: 'error',
						message: res.msg,
						duration: 5000
					})
				}
			});
		},
		openMatchDialg() { //打开比赛对话框
			this.matchForm = {};
			this.matchDialogStep1Visible = true;
			this.queryMyTeams();

		},
		adddelegateteamdialogEvent() { //打开新增代表队
			this.$refs.adddelegateteamdialog.showDialog();
		},
		callbackMyDelegateTeam() { //子组件调用父组件刷新数据
			this.queryMyTeams();
		},
		toHelp() {
			window.open('help.html')
		},
		addMatchStep1Event() {
			var own = this;
			this.queryMatchInfoByInvitateCode(function () {
				own.matchDialogStep1Visible = false;
				own.matchDialogStep2Visible = true;
			});

		},
		preStepEvent() {
			this.matchDialogStep1Visible = true;
			this.matchDialogStep2Visible = false;
			this.matchForm.contacts = "";
			this.matchForm.contactsPhone = "";
		},
		closeStep3DialogEvent() {
			this.matchDialogStep3Visible = false;
			this.pageQueryMyJoinMatchs();
		},
		setCurrTeam(data) {
			this.currTeam = data;
			var temp = { teamId: this.currTeam.id, contacts: this.currTeam.leader, contactsPhone: this.currTeam.contactPhone };
			this.matchForm = { ...temp, ...this.matchForm };
		},
		selectTeamEvent(teamId) {
			this.currTeam = this.myteams.filter(item => item.id == teamId)[0];
			this.setCurrTeam(this.currTeam);
		}



	}
}