import request from '@index/utils/request'

export function handleQueryMatchInfoByInvitateCode(invitateCode) { //根据参赛码获取比赛信息
  return request({
    url: 'joinmatch/queryMatchInfoByInvitateCode2',	
    method: 'post',
    params: {
      invitateCode: invitateCode
    }
  })
}


export function handleJoinTeamSave(data) { //参加比赛
  return request({
    url: 'joinmatch/submitJoinMatch',
    method: 'post',
    data
  })
}


export function handlePageQueryMyJoinMatchs(data) { //分页查询我参加的比赛
  return request({
    url: 'joinmatch/pageQueryMyJoinMatchs',
    method: 'post',
    data
  })
}


export function handleMyTeams() { //我的代表队
  return request({
    url: 'joindelegateteam/myTeams',
    method: 'post'
  })
}
